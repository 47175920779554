import { MutableRefObject, useEffect } from "react";

interface Props<T extends Element | null> {
  ref: MutableRefObject<T> | null;
  callback: () => void;
  options: Pick<IntersectionObserverInit, "threshold">;
}

export const useEnterViewport = <T extends Element | null>({
  ref,
  callback,
  options,
}: Props<T>) => {
  useEffect(() => {
    const current = ref?.current;

    if (current) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            callback();
          }
        },
        { threshold: options.threshold }
      );

      observer.observe(current);
      return () => observer.unobserve(current);
    }
    return undefined;
  }, [ref, callback, options]);
};

import { useEffect } from "react";

type RunOnPageProps = {
  path: string;
  callback: () => void;
};

export const useRunOnPathOnce = ({ path, callback }: RunOnPageProps) => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      if (window.location.pathname.includes(path)) {
        callback();
      }
    }
  }, [callback]);
};
